.notes-list {
  padding: 0;

  .notes-list__item {
    cursor: pointer;
    @extend %base-list-item;

    .notes-list__item-icon {
      width: 40px;
      height: 40px;
      background: var(--gray-1);
      border-radius: 50%;
      border: 1px var(--green-3) solid;
      margin-right: 20px;
      /*box-shadow: 0 0 5px 0 #444;*/
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;

      &.withpest {
        .fas,
        .fab,
        .far {
          color: red;
        }
      }

      .fas,
      .fab,
      .far {
        color: var(--green-3);
        font-size: 16px;
      }
    }

    .notes-list__item-info-wrapper {
      padding-right: 15px;
      overflow: hidden;
    }

    .notes-list__item-date {
      text-align: right;
      color: #999;
      margin-left: auto;
      white-space: nowrap;

      span {
        font-size: 1.5em;
        font-weight: 500;
      }
    }

    .notes-list__item-description {
      @extend %base-list-item-desc;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

@import "src/styles/lists";
@import "src/styles/btns";
