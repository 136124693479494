.harvest-details__wrapper {
  background: #fff;
  min-height: calc(100% - 50px);
  padding-top: var(--navbar-height);

  .harvest-details__details {
    font-size: 1.3em;
    padding: 20px;
    color: #555;

    label {
      color: #999;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
      display: block;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
}
