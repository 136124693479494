%add-new-button {
  display: block;
  text-decoration: none;
  padding: 10px;
  font-size: 30px;
  height: 60px;
  width: 60px;
  color: var(--gray-1);
  background: var(--blue-1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: fixed;
  bottom: 30px;
  right: 30px;
  box-shadow: 2px 2px 14px 0px #444;
}
