%base-list-item {
  margin: .6em auto;
  padding: 20px 20px;
  display: flex;
  /*box-shadow: 1px 1px 6px 0px #ccc;*/
  border-bottom: 1px #f1f1f1 solid;
  justify-content: space-between;
  align-items: flex-start;
}

%base-list-item-title {
  font-size: 1.5em;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--green-1);
}

%base-list-item-desc {
  font-size: 1.2em;
  color: #999;
  font-weight: 500;
}
