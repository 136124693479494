.orchard-work-action__wrapper {
  background: #fff; /*(--gray-1);*/
  min-height: calc(100% - 50px);
  padding-top: var(--navbar-height);

  .orchard-work-action__header {
    position: sticky;
    top: 0;
    margin: 0;
    z-index: 3;
    font-size: 19px;
    padding: 0 15px;
    height: 50px;
    color: #fff;
    display: flex;
    align-items: center;
    background: var(--green-3);
    width: 100%;
    box-shadow: 0 0 10px 0px var(--green-1);

    .fas,
    .far {
      margin-right: 15px;
      color: #fff;
    }
  }

  .orchard-work-action__top {
    min-height: 50px;
    background: var(--green-3);
    background: linear-gradient(var(--green-3), var(--green-2));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 15px;
    position: relative;
    top: 0;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    height: 150px;

    @media screen and (max-width: 768px) {
      height: auto;
    }

    .orchard-work-action__item-icon {
      padding: 15px;
      width: 70px;
      height: 70px;
      display: flex;
      border-radius: 50%;
      background: #fff;
      margin-top: 10px;
      border: 1px solid var(--olive);
      box-shadow: 0 0 10px 1px var(--olive);
      margin-bottom: 10px;
      justify-content: center;
      align-items: center;

      .fas,
      .fab,
      .far {
        font-size: 42px;
        color: var(--green-1);
      }
    }

    .orchard-work-action__other {
      margin: 5px 0;
      text-align: center;

      .orchard-work-action__label {
        font-weight: 400;
        margin-bottom: 10px;
      }

      .orchard-work-action__data {
        font-size: 1.2em;
      }
    }
  }

  .orchard-work-action__details {
    font-size: 1.3em;
    padding: 20px;
    color: #555;

    label {
      color: #999;
      font-size: 14px;
      font-weight: 500;
    }

    p {
      margin-bottom: 15px;
    }
  }
}
