.orchard-details__add-new-btn {
  @extend %add-new-button;

  .orchard-details__add-new-btn-tooltip {
    position: absolute;
    color: #aaa;
    font-size: 13px;
    background: #f7f7f7;
    padding: 5px 10px;
    border-radius: 2px;
    right: calc(100% + 10px);
    white-space: nowrap;

    .fa{
      margin-left: 5px;
    }
  }
}

.orchard-details__wads {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 12px 0 0 0;

  .orchard-details__wad-item {
    background-color: var(--gray-1);
    border-radius: 15px;
    margin: 5px;
    padding: 5px 10px;
    cursor: pointer;

    &.active {
      background: var(--blue-1);
      color: #fff;

      .fas {
        margin-right: 5px;
      }
    }
  }
}

/*List*/
.orchard-details__work-actions-list,
.orchard-details__comments-list {
  padding: 0;

  .orchard-details__item {
    cursor: pointer;
    @extend %base-list-item;
    /*&::before {
        content: ' ';
        height: 100%;
        width: 2px;
        background-color: #ccc;
        position: absolute;
        top: 0;
        left: 2.5em;
        z-index: -1;
      }*/
    .orchard-details__item-icon {
      width: 40px;
      height: 40px;
      background: var(--gray-1);
      border-radius: 50%;
      border: 1px var(--green-3) solid;
      margin-right: 20px;
      /*box-shadow: 0 0 5px 0 #444;*/
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;

      .fas,
      .fab,
      .far {
        color: var(--green-3);
        font-size: 16px;
      }
    }

    &.agent-application-action {
      .orchard-details__item-icon {
        border: 1px var(--green-3) solid;
        background: var(--gray-1);

        i {
          color: var(--red);
        }
      }
    }

    .orchard-details__item-info-wrapper {
      padding-right: 15px;
      flex: 1;
      overflow: hidden;
    }

    .orchard-details__item-date {
      text-align: right;
      color: #999;
      margin-left: auto;
      white-space: nowrap;

      span {
        font-size: 1.5em;
        font-weight: 500;
      }
    }

    .orchard-details__item-name {
      @extend %base-list-item-title;
    }

    .orchard-details__item-description {
      @extend %base-list-item-desc;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .orchard-details__item-duration {
      @extend %base-list-item-desc;
      padding-top: 5px;
    }
  }
}

.orchard-details__tabs {
  background: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .orchard-details__item {
    font-weight: 700;
    text-transform: uppercase;
    height: 50px;
    border-bottom: 3px var(--gray-2) solid;
    width: auto;
    color: #999;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    transition: .3s all;
    cursor: pointer;

    .orchard-details__item:active,
    .orchard-details__item:focus {
      background: blue;
    }

    &.active {
      border-bottom: 3px var(--green-3) solid;
      color: var(--green-3);
    }
  }
}

@import "src/styles/lists";
@import "src/styles/btns";
