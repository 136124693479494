.cookie-consent {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background: #ffffff7a;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .cookie-consent__text {
    background: #fff;
    padding: 60px 150px;
    border-top: 2px var(--green-2) solid;

    @media screen and (max-width: 768px) {
      padding: 30px 10px 100px 10px;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }

    h3 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 10px;
    }

    .more-options {
      padding: 0 10px;

      table {
        width: 100%;
        margin: 11px 0;
      }
    }

    .options,
    .chosen {
      margin-top: 20px;
      cursor: pointer;
      color: var(--green-2);
      font-weight: 500;
      display: flex;

      @media screen and (max-width: 768px) {
        justify-content: center;
      }
    }

    .cookie-consent__buttons {
      display: flex;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      button.some {
        height: 40px;
        margin-top: 5px;
        border: 0;
        font-weight: 700;
        background: #fff;
        color: var(--green-2);
        font-size: 14px;
        border: 1px #ddd solid;
        padding: 10px 20px;
      }

      button.all {
        height: 40px;
        margin-top: 5px;
        text-transform: uppercase;
        border: 0;
        font-weight: 700;
        background: var(--green-2);
        color: #fff;
        font-size: 14px;
        padding: 10px 20px;
        margin-left: 10px;

        @media screen and (max-width: 768px) {
          margin-top: 30px;
          margin-left: 0;
        }
      }
    }
  }
}
