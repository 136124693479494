.navigation__wrapper {
  position: fixed;
  top: 0;
  margin: 0;
  z-index: 2;
  font-size: 19px;
  padding: 0 15px;
  height: var(--navbar-height);
  color: #fff;
  display: flex;
  align-items: center;
  background: var(--green-3);
  width: 100%;
  box-shadow: 0 0 10px 0px var(--green-1);

  .navigation-panel__title {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
  }

  .navigation-panel__actions {
    margin-left: auto;
    display: flex;

    .navigation-panel__item-icon-wrapper {
      .fas, .far {
        color: #fff;
        margin-left: 15px;
        cursor: pointer;

        &.fa-trash-alt {
          margin-left: 20px;
        }
      }
    }
  }

  .navigation-panel__item-icon-wrapper {
    min-width: 30px;

    .fas {
      color: #fff;

      @media screen and (min-width: 1024px) {
        display: none;
      }
    }
  }
}

.navigation-panel {
  font-size: 1rem;
  position: absolute;
  top: var(--navbar-height);
  background: var(--gray-1);
  left: -200vw;
  bottom: 0;
  height: calc(100vh - var(--navbar-height));
  padding: 0;
  box-shadow: 2px 4px 10px 0 #333;
  border-right: 1px solid #333;
  transition: .25s all;

  @media screen and (min-width: 1024px) {
    position: relative;
    flex: 1;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    box-shadow: none;
    border-right: 1px solid #ccc;
    box-shadow: 0px 0 6px 0px #ccc;
  }

  .navigation-panel__items-wrapper {
    flex: 1 0 0;
    overflow-y: auto;
    background: var(--gray-1);
  }

  .navigation-panel__header-wrapper {
    height: 150px;
    /* background: var(--green-1); */
    background-image: url(/assets/img/home_bg_420x850.jpg);
    background-size: cover;
    background-position: left -485px;
    background-repeat: no-repeat;
    box-shadow: inset 0 -30px 40px 0px #000;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #fff;

    @media screen and (max-width: 768px) {
      background-position: left -355px;
    }

    @media screen and (max-width: 340px) {
      height: 90px;
    }
  }

  .navigation-panel__header-user-email {
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    padding: 15px 0 10px 40px;

    .fas {
      margin-right: 10px;
    }
  }

  .navigation-panel__verify-email {
    background: linear-gradient(var(--green-2), var(--green-3));
    border: 0;
    padding: 7px;
    color: #fff;
    font-weight: 500;
    padding-left: 40px;
  }

  &.expanded {
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    z-index: 2;

    .navigation-panel__background {
      opacity: 0.4;
      transition: 0.25s all;
      transition-delay: 0.2s;
    }
  }

  .navigation-panel__background {
    position: absolute;
    width: 100vw;
    background: #000;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0;
    width: 100vw;
    height: 100%;

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  .navigation-panel__item-signout {
    padding: 15px 15px 15px 40px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    color: #111;
    text-decoration: none;
    border-top: 1px #ccc solid;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: #111;

    .navigation-panel__item {
      padding: 15px 15px 15px 40px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      color: #111;
      text-decoration: none;

      &.highlighted {
        color: var(--blue-1);
      }

      .navigation-panel__item-description {
        color: #555;
        padding: 5px 0 0 0;
        font-weight: normal;
        font-size: .9em;
        text-transform: none;
      }
    }

    &.isActive {
      .navigation-panel__item {
        background: var(--gray-2);
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: 90vw;
  }

  @media screen and (min-width: 768px) {
    width: 500px;
  }
}
