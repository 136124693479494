:root {
  --green-1: #4B7415;
  --green-2: #8BC02B;
  --green-3: #79A925;
  --gray-1: #F1F0F0;
  --gray-2: #e7e7e7;
  --blue-1: #29b8f5;
  --olive: #64722a;
  --white: #fff;
  --red: #f84242;
  --navbar-height: 50px;
}

* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Roboto', 'Times New Roman', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

html, body {
  height: 100vh;
  width: 100%;
  /*overflow-y: hidden;*/
}

body {
  font-size: 12px;
  background: #000;
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  display: none;
}

.page-wrapper {
  height: 100%;
  min-height: 100%;
  /*padding-top: var(--navbar-height);*/
  position: relative;
  overflow: hidden;

  @media screen and (min-width: 1024px) {
    display: flex;
    padding-top: 0;
  }
}

.page-content-wrapper {
  /*position: relative;
  z-index: -2;*/
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  /*height: calc(100vh - var(--navbar-height));*/
  height: 100vh;

  @media screen and (min-width: 1024px) {
    /*padding-top: var(--navbar-height);*/
    height: auto;
    flex: 2;
  }
}

.form-error {
  color: #f13434;
}

/* Loader */


.loader {
  flex: 1;
  text-align: center;

  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--blue-1);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.3s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.3s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.3s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.3s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(19px, 0);
    }
  }
}

.tip {
  position: relative;
  background: var(--blue-1);
  padding: 20px;
  margin: 20px 10px;
  color: #fff;

  &-title {
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 10px;
  }
}

.form-label-additional-note {
  color: #999;
  font-size: 12px;
  margin-bottom: 5px;
}

select{
  background: #fff;
}