.orchard-details__wrapper {
  padding-top: var(--navbar-height);
  padding-bottom: 80px;
  min-height: calc(100% - 50px);
  position: relative;

  .orchard-details__top-wrapper {
    background: var(--green-3);
    background: linear-gradient(var(--green-3), var(--green-2));
    position: relative;

    &-toggle {
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: #fff;
    }

    .orchard-details__top {
      min-height: 150px;
      /*box-shadow: 0 0 5px 0px #000;*/
      display: flex;
      justify-content: space-around;
      padding: 15px;
      position: relative;
      top: 0;
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      color: #fff;

      @media screen and (max-width: 768px) {
        min-height: 50px;
      }

      .orchard-details__other {
        margin: 5px 0;
        text-align: center;
        flex: 1;

        &:first-child {
          flex: 2;
          display: flex;
          align-items: center;
          flex-direction: column;
        }

        .orchard-details__label {
          font-weight: 300;
          margin-bottom: 10px;
        }

        .orchard-details__data {
          font-size: 1.2em;

          .area-unit {
            .area-unit-superscript {
              vertical-align: super;
              font-size: .5em;
            }
          }
        }

        .orchard-details__update {
          background-color: yellow;
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
          border: none;
          padding: 4px 5px;
          border-radius: 3px;
        }
      }
    }
  }

  .orchard-details__name {
    position: sticky;
    top: 0;
    margin: 0;
    z-index: 3;
    font-size: 19px;
    padding: 0 15px;
    height: 50px;
    color: #fff;
    display: flex;
    align-items: center;
    background: var(--green-3);
    width: 100%;
    box-shadow: 0 0 10px 0px var(--green-1);

    .fas {
      margin-right: 15px;
      color: #fff;
    }
  }

  .orchard-details__sortiment {
    display: flex;
    padding: 0 20px 0px 20px;
    flex-wrap: wrap;
    justify-content: center;

    .orchard-details__sort {
      background: #fff;
      padding: 5px 10px;
      border-radius: 5px;
      margin-right: 10px;
      margin-bottom: 10px;
      color: var(--green-1);
      font-weight: 700;
    }
  }

  .todo {
    padding: 50px 10px;
    text-align: center;
    font-size: 20px;
    color: #ccc;
    font-weight: 700;

    .todo-description {
      font-size: 16px;
      font-weight: normal;
      margin-top: 30px;
    }
  }
}

@import "src/styles/lists";
