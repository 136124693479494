.harvests-list {
  padding: 0;

  .harvests-list__item {
    cursor: pointer;
    @extend %base-list-item;
    padding: 10px 20px !important;

    .harvests-list__item-icon {
      width: 40px;
      height: 40px;
      background: var(--gray-1);
      border-radius: 50%;
      border: 1px var(--green-3) solid;
      margin-right: 20px;
      /*box-shadow: 0 0 5px 0 #444;*/
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;

      .fas,
      .fab,
      .far {
        color: var(--green-3);
        font-size: 16px;
      }
    }

    .harvests-list__item-info-wrapper {
      padding-right: 15px;
      overflow: hidden;
    }

    .harvests-list__item-date {
      text-align: right;
      color: #999;
      margin-left: auto;
      white-space: nowrap;

      span {
        font-size: 1.5em;
        font-weight: 500;
      }
    }

    .harvests-list__item-name {
      @extend %base-list-item-title;
    }

    .harvests-list__item-description {
      @extend %base-list-item-desc;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .harvests-list__item-duration {
      @extend %base-list-item-desc;
      font-weight: normal !important;
      padding-top: 5px;
    }
  }
}

@import "src/styles/lists";
@import "src/styles/btns";
